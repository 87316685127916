import axios from 'axios';

/* Storage */
import { getToken } from '../helpers/storage';

axios.interceptors.request.use((config) => {

  let token = getToken();

  config.headers.Authorization = `Bearer ${token}`;
  
  return config;
},
(error) => {
  return Promise.resolve(error.message);
});