import React from 'react';
import { lazy } from '@loadable/component';

/* Components */
const Home = lazy(() => import('../Components/Home'));
const Plans = lazy(() => import('../Components/Plans'));
const Forgot = lazy(() => import('../Components/Forgot'));
const Proyects = lazy(() => import('../Components/Proyects'));
const ExpoTeach = lazy(() => import('../Components/ExpoSchool'));
const CreateAccount = lazy(() => import('../Components/CreateAccount'));
const LoginAccount = lazy(() => import('../Components/LoginAccount'));
const DocumentsLegal = lazy(() => import('../Components/LegalDocuments'));
const Windows = lazy(() => import('../Components/Windows'));

/* Components studen */
const HomeStudent = lazy(() => import('../Components/HomeStudent'))

const paths = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/buy/student/:plan',
        element: <Plans />
    },
    {
        path: '/forgot-password',
        element: <Forgot />
    },
    {
        path: '/register-student',
        element: <CreateAccount />
    },
    {
        path: '/login-student',
        element: <LoginAccount />
    },
    {
        path: '/expo/students',
        element: <ExpoTeach />
    },
    {
        path: '/proyect/student',
        element: <Proyects />
    },
    {
        path: '/courses/:teacher/cv',
        element: ''
    },
    {
        path: '/legal/:type/document/:from',
        element: <DocumentsLegal />
    },
    {
        path: '/succes/student/:studentid/:type-message',
        element: <Windows />
    },

    {
        path: 'dashboard-student/:idStudent/academic',
        element: <HomeStudent />
    }
];

export default paths;