
/*****  *****/

const KEYS_STUDENT = [
    'names',
    'lastnames',
    'email',
];

export const storeStudent = (values) => {
    try {
      KEYS_STUDENT.forEach((key) => {
        if (values[key]) {
          localStorage.setItem(key, String(values[key]));
        }
      });
      return true;
    } catch (err) {
      return err;
    }
};

export const getStudent = () => {
  const studentData = {};
  KEYS_STUDENT.forEach((key) => {
    studentData[key] = localStorage.getItem(key); // Obteniendo los datos del localStorage
  });
  return studentData;
};

export const clearStudent = () => {
  KEYS_STUDENT.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const clearByStudent = (values) => {
    try {
      KEYS_STUDENT.forEach((key) => {
        if (values[key]) {
          localStorage.setItem(key, String(values[key]));
        }
      });
      return true;
    } catch (err) {
      return err;
    }
};

/*****  *****/

export const storePlan = (value) => {
    try {
        localStorage.setItem(`plankey`, String(value));
    } catch (err) {
      return err;
    }
};

export const getPlanKey = () => {
    let studentData = '';
    studentData = localStorage.getItem('plankey'); // Obteniendo los datos del localStorage
    return studentData;
};

export const clearPlanKey = () => {
    localStorage.removeItem('plankey');
};

/*****  *****/

/* For token */
export const storeToken = (value) => {
    try {
        localStorage.setItem(`token`, String(value));
    } catch (err) {
      return err;
    }
};

export const getToken = () => {
    let studentData = '';
    studentData = localStorage.getItem('token'); // Obteniendo los datos del localStorage
    return studentData;
};

export const clearToken = () => {
    localStorage.removeItem('token');
};

/*****  *****/

/* For customerid */
const KEYS_CUSTOMER = [
  'customer',
  'studentid',
];

export const storeCustomer = (values) => {
  try {
    KEYS_CUSTOMER.forEach((key) => {
      if (values[key]) {
        localStorage.setItem(key, String(values[key]));
      }
    });
    return true;
  } catch (err) {
    return err;
  }
};

export const getCustomer = () => {
  const customerData = {};
  KEYS_CUSTOMER.forEach((key) => {
    customerData[key] = localStorage.getItem(key); // Obteniendo los datos del localStorage
  });
  return customerData;
};

export const clearCustomer = () => {
  KEYS_CUSTOMER.forEach((key) => {
    localStorage.removeItem(key);
  });
};